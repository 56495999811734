import Payment from "./Pages/Payment";
import Verify from "./Pages/Verify";
import Success from "./Pages/Success";
import Cancel from "./Pages/Cancel";
import VerifyQR from "./Pages/VerifyQR";
import EPay from "./Pages/EPay";
import QR from "./Pages/QR";
import { useSearchParams } from "react-router-dom";
import messages_en from './translations/en.json';
import messages_th from './translations/th.json';
import { IntlProvider } from 'react-intl';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useNavigate
} from "react-router-dom";

import { Fragment, useEffect, useState } from 'react';

const messages = {
  en: messages_en,
  th: messages_th,
};

function App() {
  const [token, setToken] = useState("");
  const [isQR, setIsQR] = useState(false);

  const [showPayment, setShowPayment] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let url = window.location.href;
    setToken("");
    if (url.indexOf("gateway/") != -1) {
      setToken(url.substring(url.indexOf("gateway/") + 8))
      if (url.includes("qrgateway")) {
        setIsQR(true);
      }
    } else if (url.indexOf("gateway?") != -1) {
      if (searchParams.get("STATUS") == "INCOMPLETE") {
        navigate("/cancel");
      } else {
        navigate("/success");
      };
    }
  }, []);

  const [locale, setLocale] = useState('en');

  const switchLanguage = (lang) => {
    setLocale(lang);
  };


  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Fragment>
        <Routes>
          <Route path="/e-pay/:id" element={<EPay />} />
          <Route path="/thqr" element={<QR switchLanguage={switchLanguage} locale={locale} />} />
          <Route path="/payment" element={<Verify />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
        {(token != "") &&
          <Payment token={token} qr={isQR} />
        }
      </Fragment >
    </IntlProvider>
  );
}

export default App;


