import { FormattedMessage } from 'react-intl';

const CaptureQRPayment = ({ detailPaymentRef, imgData, payloadData }) => {
    return (
        <div id="test-capture" ref={detailPaymentRef} className="w-[320px] h-full px-[10px] py-5 space-y-4 hidden">
            {/* Logo Destination */}
            <div className="flex items-center justify-center">
                <img src="/PSU-Payment-Gateway.png" style={{ width: "150px" }} />
            </div>
            {/* QR Code */}
            <div className="flex flex-col items-center justify-center">
                <div id="qr-code-container" className=" bg-white rounded-[10px] max-w-[150px] max-h-[150px] flex items-center justify-center">
                    <img className="p-[5px]" src={imgData} />
                </div>
                <div className="text-center">
                    <div className="text-[#D02B2D] flex flex-row text-[12px] font-medium items-center justify-center pt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <div>
                            <FormattedMessage id="expire_qr_code" defaultMessage="This QR code will expire in" />
                        </div>
                    </div>
                    <div className="text-[#D02B2D] text-[16px] font-medium">25/06/67  13 : 25 : 56</div>
                </div>
            </div>
            {/* Language and Download */}
            <div id="raw-amout" className="text-black w-full px-[25px] rounded-[10px] h-[50px] flex flex-row justify-center items-center space-x-2 font-bold text-[20px] ">
                <div>16,010.00</div>
                <div>THB</div>
            </div>
            {/* Detail Payment */}
            <div id="detail-payment" className="w-full bg-white rounded-[10px]  py-3 px-2">
                <div className="font-bold text-[18px] pb-2 flex flex-row space-x-1 items-center">
                    <div>
                        <FormattedMessage id="payment_detail" defaultMessage="Payment Detail" />
                    </div>
                </div>
                <div className="py-2 border-b border-t border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="pay_to_account" defaultMessage="Pay to account" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold break-words">PSU College of Computing</div>
                </div>
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="ref_one" defaultMessage="Ref1" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold break-words">{payloadData.ref1}</div>
                </div>
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="ref_two" defaultMessage="Ref2" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold break-words">{payloadData.ref2}</div>
                </div>
                <div className="py-2 border-b border-[#e5e5e5]">
                    <div className="pl-4 text-[14px]">
                        <FormattedMessage id="description" defaultMessage="Description" />
                    </div>
                    <div className="pl-4 text-[16px] font-bold">{payloadData.description}</div>
                </div>
                <div className="pt-2 w-full flex justify-center">
                    <img src="/psu1.png" style={{ width: "150px" }} />
                </div>
            </div>
        </div>
    );
};

export default CaptureQRPayment;