import { Stack, Button, Typography, Box, Avatar } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Verify = () => {
  const navigate = useNavigate();
  const [payStateMessage, setPayStageMessage] = useState("");
  let _endPointVerifyPayment = process.env.REACT_APP_PGW_QR_CALLBACK;
  useEffect(() => {
    let payRef = localStorage.getItem("payRef");
    if (payRef) {
      axios.get(_endPointVerifyPayment + "?ref=" + payRef).then((res) => {
        let msg = res.data;
        if (msg.statusCode != 1) {
          setPayStageMessage("There is an error processing payment verification request. Please try again later");
        } else {
          if (msg.data.redirectUrl) {
            window.location = msg.data.redirectUrl
          }
          else if (msg.data.succeed) {
            navigate("/success")
          } else {
            navigate("/cancel")
          }
        }
        //localStorage.clear();
      }).catch((err) => {
        setPayStageMessage("There is an error processing payment verification request. Please try again later")
        //localStorage.clear();
      })

    } else {
      setPayStageMessage("No payment is made yet")
    }

  }, [])

  return (
    <Stack>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <Typography
          variant="h4"
          component="h2"
          sx={{ mt: 3 }}
          fontWeight="bold"
        >
          Verifying Payment with your Bank Provider
        </Typography>


        <Typography
          variant="h4"
          component="h4"
          sx={{ mt: 3 }}
          fontWeight="bold"
        >
          {payStateMessage}
        </Typography>
      </Box>
    </Stack>
  );
};

export default Verify;
