import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

const DesktopQRPayment = ({ imgData, payloadData, handleOptionSelect, locale, toggleDropdown, isOpen, options, captureScreen, dropdownRef }) => {
    
    return (
        <div className="md:flex hidden w-full h-screen justify-center items-center space-x-8">
            {/* Payment Detail */}
            <div className="w-[484px]">
                <div className="shadow-md text-white flex flex-row justify-center items-center space-x-2 font-bold  h-[135px] w-full rounded-t-[20px] bg-gradient-to-r from-[#143C6D] to-[#3E91F9]">
                    <div className="text-[48px] leading-none">16,000.00</div>
                    <div className="text-[32px] leading-none">THB</div>
                </div>
                <div className="w-full bg-white p-5 shadow-md">
                    <div className="flex flex-row space-x-1 pb-5 items-center">

                        <div className="text-[32px] font-bold">
                            <FormattedMessage id="payment_detail" defaultMessage="Payment Detail" />
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-[35px] h-[35px]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                        </svg>
                    </div>
                    <div className="w-full space-y-5">
                        <div className="grid grid-cols-2">
                            <div className="text-[20px]">
                                <FormattedMessage id="pay_to_account" defaultMessage="Pay to account" />
                            </div>
                            <div className="text-[20px] font-semibold">
                                <div>PSU College of Computing</div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="text-[20px]">
                                <FormattedMessage id="ref_one" defaultMessage="Ref1" />
                            </div>
                            <div className="text-[20px] font-semibold">{payloadData.ref1}</div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="text-[20px]">
                                <FormattedMessage id="ref_two" defaultMessage="Ref2" />
                            </div>
                            <div className="text-[20px] font-semibold">{payloadData.ref2}</div>
                        </div>
                        <div className="grid grid-cols-2">
                            <div className="text-[20px]">
                                <FormattedMessage id="description" defaultMessage="Description" />
                            </div>
                            <div className="text-[20px] font-semibold">{payloadData.description}</div>
                        </div>
                        <div className="w-full flex justify-center items-center">
                            <img className="max-w-[300px]" src="https://computing.psu.ac.th/th/wp-content/uploads/2018/03/PSU-CoC-ENG_01.png" />
                        </div>
                    </div>
                </div>
                <div className="relative inline-block text-left w-full">
                    <div
                        className="shadow-md cursor-pointer px-[20px] text-white flex flex-row items-center justify-between h-[86px] w-full rounded-b-[20px] bg-gradient-to-r from-[#143C6D] to-[#3E91F9] hover:from-[#2c507c] hover:to-[#519cfa]"
                        onClick={toggleDropdown}
                    >
                        <div className="text-[28px] leading-none font-semibold">
                            {locale ? locale.toUpperCase() : ''}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className={`size-8 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>
                    </div>

                    {/* Dropdown */}
                    {isOpen && (
                        <div ref={dropdownRef} className="absolute top-full right-0 mt-1 w-full bg-white rounded-[20px] shadow-lg mb-10 my-[10px]">
                            {options.map((option) => (
                                <div
                                    key={option}
                                    className={`text-[28px] font-semibold px-3 py-2 cursor-pointer ${option === locale
                                        ? 'bg-[#143C6D] text-white rounded-[20px]'
                                        : 'hover:bg-gray-200 hover:rounded-[20px]'
                                        }`}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option.toUpperCase()}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {/* QR code */}
            <div className="w-[340px] flex items-center flex-col h-fit">
                <div className="mb-3">
                    <img src="/PSU-Payment-Gateway.png" style={{ width: "300px" }} />
                </div>
                <div className=" bg-white rounded-[10px] max-w-[300px] max-h-[300px] flex items-center justify-center shadow-md mb-4">
                    <img className="p-[5px]" src={imgData} />
                </div>
                <div className="max-w-[300px] w-full bg-white py-1.5 px-3 rounded-[10px] shadow-md flex flex-row items-center space-x-2 justify-center cursor-pointer hover:bg-[#f0f0f0]" onClick={() => captureScreen()}>
                    <div className="font-bold text-[24px]">
                        <FormattedMessage id="download" defaultMessage="Download" />
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>
                    </div>
                </div>
                <div className="text-center pt-3">
                    <div className="text-[#D02B2D] flex flex-row text-[20px] font-medium items-center justify-center pt-2 leading-none space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <div>
                            <FormattedMessage id="expire_qr_code" defaultMessage="This QR code will expire in" />
                        </div>
                    </div>
                    <div className="text-[#D02B2D] text-[32px] font-medium leading-1">25/06/67  13 : 25 : 56</div>
                </div>
            </div>
        </div>
    );
};

export default DesktopQRPayment;