import { Stack, Button, Typography, Box, Avatar } from "@mui/material";
import { red } from "@mui/material/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";

const Cancel = () => {

  const [ returnURL, setReturnURL ] = useState("");
  useEffect(()=>{
    let returnURL = localStorage.getItem("returnURL");
    if (returnURL) {
      setReturnURL(returnURL) 
      localStorage.clear();
    }
  }, [])

  return (
    <Stack>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: red[500], width: "150px", height: "150px" }}>
          <CancelIcon sx={{ width: "150px", height: "150px" }} />
        </Avatar>
        <Typography
          variant="h4"
          component="h2"
          sx={{ mt: 3 }}
          fontWeight="bold"
        >
          Payment Failed !
        </Typography>
        <Button
          sx={{ mt: 10, width: "150px" }}
          variant="contained"
          size="small"
          onClick={()=>{ 
            window.location=returnURL;
          }}
        >
          Back to PSU Internal System
        </Button>
      </Box>
    </Stack>
  );
};

export default Cancel;
