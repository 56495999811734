import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import html2canvas from 'html2canvas';
import { FormattedMessage } from 'react-intl';
import DesktopQRPayment from "../component/DesktopQRPayment";
import MobileQRPayment from "../component/MobileQRPayment";
import CaptureQRPayment from "../component/CaptureQRPayment";

const QR = ({ switchLanguage, locale }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ref = searchParams.get("ref");
    const [imgData, setImgData] = useState("");
    let _endPointDetailQRPayment = process.env.REACT_APP_PGW_LOAD_QR_CODE;
    let _endPointVerifyPayment = process.env.REACT_APP_PGW_QR_CALLBACK;
    const [payloadData, setPayloadData] = useState({
        qrData: {
            qrImage: "",
            qrRawData: "",
        },
        ref1: "",
        ref2: "",
        description: "",
    });
    const detailPaymentRef = useRef(null);
    const dropdownRef = useRef(null);
    const dropdownMobileRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const options = ['th', 'en']; // Example options
    const [payStateMessage, setPayStageMessage] = useState("");
    const [isHaveQrCode, setIsHaveQrCode] = useState(false)

    useEffect(() => {
        let handler = (e) => {
            if (isOpen && ((dropdownRef.current && !dropdownRef.current.contains(e.target)) && (dropdownMobileRef.current && !dropdownMobileRef.current.contains(e.target)))) {
                setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    })

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        switchLanguage(option);
        toggleDropdown();
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(_endPointDetailQRPayment + "?ref=" + ref);
                const msg = response.data;
                if (msg) {
                    setIsHaveQrCode(true)
                    setImgData("data:image/jpeg;base64," + msg.qrData.qrImage);
                    setPayloadData(msg);
                }
            } catch (error) {
                setIsHaveQrCode(false)
                setPayStageMessage("There is an error processing QR code payment request. Please try again later")
                alert("พบปัญหาในการเชื่อมต่อกับระบบ Payment Gateway กรุณาทดลองใหม่ในภายหลัง");
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(_endPointVerifyPayment + "?ref=" + ref).then((res) => {
                let msgData = res.data;
                if (msgData.statusCode === 1 && msgData.data.succeed) {
                    if (msgData.data.redirectUrl) {
                        window.location = msgData.data.redirectUrl
                    }
                }
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [ref]);

    const captureScreen = () => {
        if (detailPaymentRef.current) {
            // Make the element visible temporarily
            detailPaymentRef.current.style.display = 'block';

            html2canvas(detailPaymentRef.current, {
                width: 320
            })
                .then((canvas) => {
                    // Convert the canvas to a data URL
                    const dataUrl = canvas.toDataURL();
                    // Create a temporary anchor element
                    const downloadLink = document.createElement('a');
                    downloadLink.href = dataUrl;
                    downloadLink.download = 'payment_detail.png'; // Specify the filename
                    // Simulate a click event to trigger the download
                    downloadLink.click();
                    // Clean up the temporary anchor element
                    document.body.removeChild(downloadLink);

                    // Hide the element again
                    detailPaymentRef.current.style.display = 'none';
                })
                .catch((error) => {
                    console.error('Error capturing screen:', error);
                    // Ensure the element is hidden in case of an error
                    detailPaymentRef.current.style.display = 'none';
                });
        }
    };

    return (

        <div className="w-full font-notosans">
            {isHaveQrCode ? (
                <div>
                    {/* Mobile */}
                    <MobileQRPayment
                        payloadData={payloadData}
                        imgData={imgData}
                        locale={locale}
                        handleOptionSelect={handleOptionSelect}
                        toggleDropdown={toggleDropdown}
                        isOpen={isOpen}
                        options={options}
                        captureScreen={captureScreen}
                        dropdownMobileRef={dropdownMobileRef}
                    />
                    {/* Desktop */}
                    <DesktopQRPayment
                        payloadData={payloadData}
                        imgData={imgData}
                        locale={locale}
                        handleOptionSelect={handleOptionSelect}
                        toggleDropdown={toggleDropdown}
                        isOpen={isOpen}
                        options={options}
                        captureScreen={captureScreen}
                        dropdownRef={dropdownRef}
                    />
                    {/* Capture */}
                    <CaptureQRPayment
                        detailPaymentRef={detailPaymentRef}
                        payloadData={payloadData}
                        imgData={imgData}
                    />
                </div>) : (
                <div className="text-center">
                    {payStateMessage}
                </div>
            )}

        </div>
    )

}

export default QR;
