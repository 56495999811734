import React from 'react';

const CurrencyDisplay = ({ amount }) => {
  const formattedAmount = new Intl.NumberFormat({
    style: 'currency',
    currency: 'THB',
  }).format(amount);

  return <span>{formattedAmount}</span>;
};

export default CurrencyDisplay;